// Custom.scss

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
// $primary: rgb(187, 38, 26);
$primary: rgb(0,0,0);

// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/helpers";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/close";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../node_modules/bootstrap/scss/utilities/api";

// 6. Add additional custom code here
.startStreamModal .user-pin {
  font-size: 3rem;
  text-align: center;
  letter-spacing: 1rem;
}
.overlay-loader {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 200; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
}
.loader-item {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.main-header {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.footer {
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1);
}
